import React, { useRef } from "react";
// import { Col, Row } from "react-bootstrap";

import "./App.css";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import TravelInfoCarousel from "./Components/Carousel/Carousel";
import Navbar from "./Components/Navbar/Navbar";
import HomePage from "./Components/HomePage/HomePage";
import Documentations from "./Components/Documentations/Documentations";
import Itinarary from "./Components/Itinarary/Itinarary";
import PhotoGallery from "./Components/Gallery/Gallery";
// import Info from "./Components/Info/Info";
import Contact from "./Components/Contact/Contact";
// import Feedback from "./Components/Feedback/Feedback";
import CenterofExcellence from "./Components/CenterofExcellence/CenterofExcellence";
// import BvbWelcome from "./Components/Itinarary/BvbWelcome";
import MultipleGallery from "./Components/MultipleGallery/MultipleGallery";
import Management from "./Components/Management/Management";
// import News from "./Components/News/News";
// import { FaInstagram, FaFacebook, FaAward } from "react-icons/fa";
import Nios from "./Components/Nios/Nios";
import ToastNotification from "./Components/ToastNotification/ToastNotification";
// import TeacherTraining from "./Components/Info/TeacherTraining/TeacherTraining";

function App() {
  const pageRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const scrollToPage = (pageNumber) => {
    const navbarHeight = document.querySelector(".navbar-main").offsetHeight; // Get navbar height
    const pageOffset = pageRefs[pageNumber].current.offsetTop - navbarHeight; // Calculate offset
    // const pageOffset =
    //   pageRefs[pageNumber].current.offsetTop - navbarHeight - 20; // Adjust the offset with a buffer of 20px

    window.scrollTo({
      top: pageOffset,
      behavior: "smooth",
    });
  };
  return (
    <>
      {/* <Routes>
        <Route path="/teacher-training" element={<TeacherTraining />} />
      </Routes> */}
      <div className="App">
        <ToastNotification />
        <Navbar scrollToPage={scrollToPage} />
        <HomePage className="banner" pageRef={pageRefs[0]} pageNumber={1} />
        <Documentations pageRef={pageRefs[1]} pageNumber={2} />
        <Itinarary pageRef={pageRefs[3]} pageNumber={4} />
        <MultipleGallery pageRef={pageRefs[8]} pageNumber={9} />
        <Nios pageRef={pageRefs[11]} pageNumber={12} />
        <Management pageRef={pageRefs[6]} pageNumber={7} />
        <CenterofExcellence pageRef={pageRefs[5]} pageNumber={6} />
        {/* <Feedback pageRef={pageRefs[4]} pageNumber={5} /> */}
        <PhotoGallery pageRef={pageRefs[7]} pageNumber={8} />
        <Contact pageRef={pageRefs[10]} pageNumber={11} />
        <div className="admission-icons">
          <div
            className="award"
          >
            <span>Admissions Open!</span>
            <div className="links">
              <a
                href="https://bvbthrissur.amserp.in/index.php/user/login/loginguest"
                target="_blank"
                rel="noopener noreferrer"
              > 
                KG Admission
              </a>
              <a
                href="https://bvbthrissur.amserp.in/index.php/core/studentregistration/create"
                target="_blank"
                rel="noopener noreferrer"
              > 
                Class XI Admission
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
