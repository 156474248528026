import React, { useState } from "react";
import "./ToastNotification.css";
import logo from "../Assets/logo/logo.png";

const ToastNotification = () => {
  const [show, setShow] = useState(true);


  const handleToastClose = () => {
    setShow(false); // Close the modal
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="close-btn" onClick={handleToastClose}>
          &times;
          
        </div>
        <img src={logo} alt="" />
        <p>Class XI Admission is Open now!</p>
        <a 
          href="https://bvbthrissur.amserp.in/index.php/core/studentregistration/create"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          Click here
        </a>
        <p>KG Admission is Open now!</p>
        <a 
          href="https://bvbthrissur.amserp.in/index.php/user/login/loginguest"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          Click here
        </a>
      </div>
    </div>
  );
};

export default ToastNotification;