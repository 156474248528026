import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Col, Row } from "react-bootstrap";
import Pattabhiraman from "../Assets/Management/Pattabhiraman.jpg";
// import ChithranNamboodiripad from "../Assets/Management/ChithranNamboodiripad.jpg";
import HaridasMenon from "../Assets/Management/HaridasMenon.jpg";
import Radhakrishnan from "../Assets/Management/Radhakrishnan.jpeg";
import Venugopal from "../Assets/Management/Venugopal.jpg";
import Unnikrishnan from "../Assets/Management/Unnikrishnan.jpeg";
import Ramachandran from "../Assets/Management/Ramachandran.jpeg";
import Sivaramakrishnan from "../Assets/Management/Sivaramakrishnan.png";
import Indiradevi from "../Assets/Management/Indiradevi.png"
import "../Feedback/Feedback.scss"

const Management = ({ pageRef, pageNumber }) => {
  const [index, setIndex] = useState(0);
  const [startCarousel, setStartCarousel] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCarousel(true);
        } else {
          setStartCarousel(false);
        }
      },
      { threshold: 0.5 } // Start when at least 50% of the carousel is visible
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="feedback-container" ref={pageRef} id={`page-${pageNumber}`}>
      <div className="container">
        <Row>
          <Col md={12} className="feedback-header">
            <div className="header-top title">
              MANAGEMENT <span className="title">COMMITTEE</span>
            </div>
            <div className="header-sep"></div>
            <div className="header-bottom">
              {/* <span>Series of Teachers Training Programmes </span> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="feedback-carousel container" ref={carouselRef} >
      {startCarousel && (
        <Carousel
          className="carousel"
          activeIndex={index}
          onSelect={handleSelect}
        >
          {/* 2019 */}
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    {" "}
                    <Row className="carousel-footer">
                      <div className="carousel-footer-container">
                        <div className="carousel-footer-icon">
                          <img src={Pattabhiraman} alt="" />
                        </div>
                      </div>
                    </Row>
                    <div className="carousel-footer-text content">
                          <h4 style={{ color: "#000000", fontWeight: "600" }}>
                            T.S. Pattabhiraman
                          </h4>
                        </div>
                    <h4>CHAIRMAN</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>Managing Director</span>
                  <span>M/s. Kalyan Silks Group,</span>
                  <span>Palace Road, Thrissur 680 020</span>
                  <span>Email: mdpa@kalyansilks.com</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                {" "}
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={HaridasMenon} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">

                  <Col md={12} className="carousel-Header-text">
                  <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        P. Haridas Menon
                      </h4>
                    </div>
                    <h4>VICE-CHAIRMAN</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>District Educational Officer (Retd)</span>
                  <span>"Lakshmi", Kalveri Road, Poothole</span>
                  <span>Thrissur 680 004</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Venugopal} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        N. Venugopal
                      </h4>
                    </div>
                    <h4>VICE-CHAIRMAN</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>Senior Audit Officer (Retd)</span>
                  <span>"Indeevaram", Cherumukk Temple Road</span>
                  <span>Thrissur 680 020</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Radhakrishnan} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        K.P.Radhakrishnan
                      </h4>
                    </div>
                    <h4>HON. SECRETARY</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>"Sreekovil"</span>
                  <span>Marar Road, Thrissur 680 001</span>
                  <span>Email: kpr.sreekovil@gmail.com</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Ramachandran} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        V. Ramachandran
                      </h4>
                    </div>
                    <h4>ASSOCIATE SECRETARY</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>Finance Consultant</span>
                  <span>"Vinayaka"</span>
                  <span>Near Raji Nursing Home, Thrissur 680 020</span>
                  <span>Email: ramc963@gmail.com</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Unnikrishnan} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        I. Unnikrishnan
                      </h4>
                    </div>
                    <h4>ASSOCIATE SECRETARY</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>Chartered Accountant</span>
                  <span>"Mannath House"</span>
                  <span>Mannath Lane, Thrissur 680 001</span>
                  <span>Email: unni@yogloans.com</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Indiradevi} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        Dr. P. Indiradevi
                      </h4>
                    </div>
                    <h4>ASSOCIATE SECRETARY</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>1C, Skyline Avenue Suites</span>
                  <span>Avenue Road, Mundupalam</span>
                  <span>Thrissur 680 005</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="carousel-container" interval={3000}>
            <Row>
              <Col md={12}>
                <Row className="carousel-footer">
                  <div className="carousel-footer-container">
                    <div className="carousel-footer-icon">
                      <img src={Sivaramakrishnan} alt="" />
                    </div>
                  </div>
                </Row>
                <Row className="carousel-Header">
                  <Col md={12} className="carousel-Header-text">
                    <div className="carousel-footer-text content">
                      <h4 style={{ color: "#000000", fontWeight: "600" }}>
                        S. Shivaramakrishnan
                      </h4>
                    </div>
                    <h4>HON. TREASURER</h4>
                  </Col>
                </Row>
                <Row className="carousel-body content">
                  <span>Chartered Accountant</span>
                  <span>M/s. Vasu & Sivaram</span>
                  <span>Korappath Lane, Thrissur 680 020</span>
                  <span>Email: vasuandsivaram@gmail.com</span>
                  <p></p>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      )}
      </div>
      {showMore ? (
        <div className="container">
          <Row className="row">
            <Col md={12} className="">
              <Row className="row d-flex justify-content-between ">
                <Col md={4} className=" ">
                  <div className=" management-card m-2 content">
                    <strong>Shri K.P. Achuthan</strong>
                    <br />
                    <span> Addl. commissioner of Income Tax (Retd) </span>
                    <br />
                    <span> "Paliyam", Parakkotte Line,</span>
                    <br />
                    <span> Patturaikkal,</span>
                    <br />
                    <span> Thrissur-680 022,</span>
                    <br />
                    <span> Email: kp.achutan@gmail.com</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Shri T.R. Vijayakumar</strong>
                    <br />
                    <span>Managing Director,</span>
                    <br />
                    <span>Elite Super Market,</span>
                    <br />
                    <span>Round North, Thrissur-680 001</span>
                    <br />
                    <span> Email: eliteindia.kerala@gmail.com</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Adv. A.Sureshan, </strong>
                    <br />
                    <span>"Anurag", S.N. Park,</span>
                    <br />
                    <span>Main Road, Ayyanthole,</span>
                    <br />
                    <span>Thrissur-680 006</span>
                    <br />
                    <span>Email: advocatesuresh@ya</span>
                  </div>
                </Col>
              </Row>
              <div className="row d-flex justify-content-around">               
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Shri E. Ramankutty</strong>
                    <br />
                    <span>Director, Bharatiya Vidya Bhavan,</span>
                    <br />
                    <span>Kochi Kendra,</span>
                    <br />
                    <span>T.D. Road, Ernamkulam,</span>
                    <br />
                    <span>Kochi-682 011</span>
                    <br />
                    <span>Email: erwarrier@gmail.com</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Dr.A.A.Baby</strong>
                    <br />
                    <span>"Jyothis"</span>
                    <br />
                    <span>6/1358, Mylipadam,.</span>
                    <br />
                    <span>Thrissur-East,680 005</span>
                    <br />
                    <span>Email: aabahy55@gmail.com</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Shri K.P. Karunakaran</strong>
                    <br />
                    <span>Plot No.394,4th Lane,</span>
                    <br />
                    <span>M.G. Nagar, Punkunnam,</span>
                    <br />
                    <span>Thrissur-680 002</span>
                  </div>
                </Col>
              </div>
              <div className="row d-flex justify-content-around">
              <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Dr. V.M. Gopala Menon IAS</strong>
                    <br />
                    <span>"Saketham",St. Thomas Nagar,</span>
                    <br />
                    <span>House no 50,</span>
                    <br />
                    <span>Aranattukara P.O,</span>
                    <br />
                    <span>Thrissur-680 618</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Shri S. Suresh</strong>
                    <br />
                    <span>Deputy Director, Bharatiya Vidya Bhavan,</span>
                    <br />
                    <span>Kochi Kendra,</span>
                    <br />
                    <span>T.D. Road, Ernamkulam,</span>
                    <br />
                    <span>Kochi-682 011</span>
                    <br />
                    <span>(Central Bhavan Nominee)</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className=" management-card m-2 content">
                    <strong>Shri P.M. Madkaikar</strong>
                    <br />
                    <span>Chief Accountant,</span>
                    <br />
                    <span>Bharatiya Vidya Bhavan,</span>
                    <br />
                    <span>Dr. K.M. Munshi Road,</span>
                    <br />
                    <span>Chowpatty,</span>
                    <br />
                    <span>Mumbai-400 007</span>
                    <br />
                    <span>(Central Bhavan Nominee)</span>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <div className="container">
        <Row>
          <Col md={12}>
            <div className="show-more" onClick={() => setShowMore(!showMore)}>
              <div className="more content">Show {showMore ? "less" : "more..."}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Management;
