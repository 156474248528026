import React, { useState, useEffect } from "react";
import "./Homepage.css"

const HomePage = ({ pageRef, pageNumber }) => {
  const images = [
    // '/HeroImages/HeroImage_1.jpg',
    '/HeroImages/HeroImage_2.jpg',
    '/HeroImages/HeroImage_3.jpeg',
    '/HeroImages/HeroImage_4.jpeg',
    '/HeroImages/HeroImage_5.jpeg',
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div ref={pageRef} id={`page-${pageNumber}`} className="home">
      <img src={images[currentIndex]} alt="" />
    </div>
  )
}

export default HomePage
