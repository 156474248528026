import React from 'react'
import Modal from "react-bootstrap/Modal";
import "./Career.css"

const CareerContent = (props) => {
  return (
    <div>
      {" "}
      <Modal
        className=""
        // size="xl"
        fullscreen
        show={props.showModal}
        onHide={props.handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className='career-header'>
            <div>
              <h1 className="career-heading">
                CAREER OPPORTUNITIES
              </h1>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container">
        <h2 className="career-title">Teaching Positions</h2>
        <p>We are looking for dedicated and qualified teachers for the following subjects:</p>
        <ul>
          <li>English</li>
          <li>Mathematics</li>
          <li>Malayalam</li>
        </ul>
        <h3 className="career-subtitle">Qualifications:</h3>
        <ul>
          <li>Post Graduation with B.Ed</li>
        </ul>
        <h3 className="career-subtitle">Additional Positions:</h3>
        <ul>
          <li>Physical Education
            <ul>
              <li>Graduation/Post Graduation in Physical Education</li>
            </ul>
          </li>
          <li>Craft
            <ul>
              <li>Graduation or Post Graduation in Fine Arts or equivalent</li>
            </ul>
          </li>
        </ul>
        <h3 className="career-subtitle">Experience:</h3>
        <p>Teaching experience will be given due weightage.</p>
        <h3 className="career-subtitle">Salary:</h3>
        <p>A competitive salary will be offered to successful candidates.</p>
        <h3 className="career-subtitle">Selection Procedure</h3>
        <ol>
          <li><span className="list-heading">Merit-Based Selection:</span> Candidates will be assessed based on merit.</li>
          <li><span className="list-heading">Written Test:</span> Selected candidates will be invited to participate in a written test.</li>
          <li><span className="list-heading">Interview and Demonstration Class:</span> Qualified candidates will be shortlisted for an interview and a demonstration class.</li>
        </ol>
  
        <hr />
  
        <h2 className="career-title">Office Assistant / Technical Assistant Positions</h2>
        <h3 className="career-subtitle">Qualifications:</h3>
        <ul>
          <li>B.Com / Diploma in Commercial Practice</li>
          <li>Fair knowledge of Tally and accounting is desirable.</li>
          <li>Experience: Not less than 3 years.</li>
        </ul>
        <h3 className="career-subtitle">Selection Procedure:</h3>
        <ol>
          <li><span className="list-heading">Written Skill Test:</span> Selected applicants will be called for a written skill test.</li>
          <li><span className="list-heading">Interview:</span> Shortlisted candidates will be invited for an interview.</li>
        </ol>

        <hr />

        <h3 className="career-subtitle">Note:</h3>
        <p>The decision of the selection board will be final.</p>  

      </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CareerContent