import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.scss";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="container justify-content-center align-items-center">
        <Row className="row-col">
          <Col className="col-md-10">
            <Row>
              {" "}
              <span className="footer-text">Copyright © 2023. All Rights Reserved</span>
            </Row>
            <Row>
              <span className="footer-text"> Designed & Developed By: Opine infotech pvt.ltd</span>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="social-icons">
          <a
            href="https://www.instagram.com/bvbk.t/"
            target="_blank"
            className="insta"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/bvbk.t"
            target="_blank"
            className="fb"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </div>
    </div>
  );
};

export default Footer;
