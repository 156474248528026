import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Navbar.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import { FaHome } from "react-icons/fa";
import logo from "../Assets/logo/logo.png";
import { Dropdown, NavDropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import TeachersTraining from "./TeachersTraining";
import ProspectusPDF from "../Assets/pdf/Prospectus.pdf";
import Recognition from "../Assets/pdf/Recognitions.pdf";
import FeeStructure from "../Assets/pdf/fees_structure.pdf";
import CourseContent from "./CourseContent";
import { useMediaQuery } from "@uidotdev/usehooks";
import { FaBars, FaTimes } from "react-icons/fa";
import CareerContent from "./CareerContent";

const NavbarResponsive = ({ scrollToPage }) => {
  const dropdownRef = useRef(null);
  const isSecondToggle = useRef(false);
  // const navRef = useRef();
  // const [icons, seticons] = useState(false);
  // const handleClick = () => {
  //   seticons(!icons);
  // };
  const [showModal, setShowModal] = useState(false);
  const [showCourseContentModal, setShowCourseContentModal] = useState(false);
  const [showCareerContentModal, setShowCareerContentModal] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCBSEDropdown, setShowCBSEDropdown] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const isNavbarHamburger = useMediaQuery("screen and (min-width: 1024px)");
  const [showAdmissionDropdown, setShowAdmissionDropdown] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    setShowCourseContentModal(false);
    setShowCareerContentModal(false);
  };
  const toggleDropdown = (...e) => {
    console.log("e first", e);
    if (isSecondToggle.current) {
      isSecondToggle.current = false;
      return;
    }

    // console.log("e class", e.originalEvent.target.className);
    // if (e[1].originalEvent.target.className.contains("courseDD")) return;
    setShowDropdown(!showDropdown);
    Dropdown.Toggle();
    alert("something happened");
    dropdownRef.current.show();
  };

  const toggleAdmissionDropdown = (isOpen) => {
    setShowAdmissionDropdown(isOpen); 
  }

  const toggleCBSEDropdown = (...e) => {
    console.log("e inside", e);
    isSecondToggle.current = true;
    setShowCBSEDropdown(!showCBSEDropdown);
  };

  const handleTeachersTraining = () => {
    setShowModal(true);
    setIsNavbarExpanded(false);
  };

  const handleCareerContent = () => {
    setShowCareerContentModal(true);
    setIsNavbarExpanded(false);
  };

  const handleCourseContent = (e) => {
    setShowCourseContentModal(true);
    setIsNavbarExpanded(false);
  };

  const openProspectusPDF = () => {
    window.open(ProspectusPDF, "_blank");
    setIsNavbarExpanded(false);
  };

  const openFeesStructurePDF = () => {
    window.open(FeeStructure, "_blank");
    setIsNavbarExpanded(false);
  };

  const openRecognitionPDF = () => {
    window.open(Recognition, "_blank");
    setIsNavbarExpanded(false);
  };
  // useEffect(() => {
  //   if (scrollToPage) {
  //     setIsNavbarExpanded(false);
  //   }
  // }, [scrollToPage]);
  const handleNavLinkClick = (pageNumber) => {
    scrollToPage(pageNumber);
    setIsNavbarExpanded(false); // Close the navbar
  };
  const handleNavbarToggle = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  // Close navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsNavbarExpanded(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  

  return (
    <>
      <Navbar
        collapseOnSelect
        expand={isNavbarHamburger}
        // style={{ maxWidth: "100%" }}
        sticky="top"
        className="navbar-main"
        expanded={isNavbarExpanded}
        onToggle={() => {
          // console.log("somemfidgukvhhcf");
          setIsNavbarExpanded(!isNavbarExpanded);
        }} // Toggle navbar state
      >
        <Container fluid>
          <Navbar.Brand
            onClick={() => handleNavLinkClick(0)}
            className="me-auto"
          >
            <img src={logo} alt="" />
          </Navbar.Brand>
          {/* <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            collapseOnSelect
          /> */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleNavbarToggle}
            className="navbar-toggler"
          >
            {isNavbarExpanded ? <FaTimes color="white" size={24} /> : <FaBars color="white" size={24} />}
          </Navbar.Toggle>

          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ overflowX: "clip" }}
          >
            <Nav className="ms-auto navbar-item-container">
              <Nav.Link
                onClick={() => handleNavLinkClick()}
                style={{ whiteSpace: "nowrap" }}
                href="https://bvbthrissur.amserp.in/index.php/user/login"
                target="_blank"
              >
                PAY ONLINE
              </Nav.Link>

              <span className="mobile-nav">
                <Nav.Link 
                  href="https://bvbthrissur.amserp.in/index.php/user/login/loginguest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KG ADMISSION
                </Nav.Link>
                <Nav.Link 
                  href="https://bvbthrissur.amserp.in/index.php/core/studentregistration/create"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CLASS XI, XII ADMISSION
                </Nav.Link>
              </span>

              <Dropdown
                show={showAdmissionDropdown}
                onToggle={(e) => toggleAdmissionDropdown(e)}
                className="custom-dropdown"
              >
                <Dropdown.Toggle as={Nav.Link} style={{ whiteSpace: "nowrap" }}>
                  {/* QUICK  */}
                  ADMISSIONS
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {" "}
                  <DropdownItem
                    href="https://bvbthrissur.amserp.in/index.php/user/login/loginguest"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dd-items"
                  >
                    KG ADMISSION
                  </DropdownItem>
                  <DropdownItem
                    href="https://bvbthrissur.amserp.in/index.php/core/studentregistration/create"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dd-items"
                  >
                    CLASS XI, XII ADMISSION
                  </DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link
                onClick={() => handleNavLinkClick()}
                style={{ whiteSpace: "nowrap" }}
                href="https://bvbpoochatty.in/"
                target="_blank"
              >
                BVB POOCHATTY
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavLinkClick()}
                style={{ whiteSpace: "nowrap" }}
                href="https://www.bvbpottore.com/"
                target="_blank"
              >
                {" "}
                KMBVM POTTORE
              </Nav.Link>
              <Nav.Link onClick={handleCareerContent}>CAREERS</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick(7)}>GALLERY</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick(10)}>CONTACT</Nav.Link>

              <span className="mobile-nav">
                <Nav.Link onClick={() => setShowModal(true)}>TEACHERS TRAINING</Nav.Link>
                <Nav.Link href="https://www.cbse.gov.in/cbsenew/cbse.html" target="_blank">CBSE</Nav.Link>
                <Nav.Link onClick={openProspectusPDF} target="_blank">PROSPECTUS</Nav.Link>
                <Nav.Link onClick={openRecognitionPDF} target="_blank">CENTRAL & STATE RECOGNITION</Nav.Link>
                <Nav.Link onClick={handleCourseContent} target="_blank">COURSE CONTENT</Nav.Link>
                <Nav.Link onClick={openFeesStructurePDF} target="_blank">FEE STRUCTURE</Nav.Link>
              </span>

              <Dropdown
                show={showDropdown}
                onToggle={(e) => toggleDropdown(e)}
                className="custom-dropdown"
              >
                <Dropdown.Toggle as={Nav.Link} style={{ whiteSpace: "nowrap" }}>
                  {/* QUICK  */}
                  MORE
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {" "}
                  <DropdownItem
                    className="dd-items"
                    onClick={handleTeachersTraining}
                  >
                    TEACHERS TRAINING
                  </DropdownItem>
                  <DropdownItem
                    href="https://www.cbse.gov.in/cbsenew/cbse.html"
                    className="dd-items"
                    target="_blank"
                    // onClick={() => setShowModal(true)}
                  >
                    CBSE
                  </DropdownItem>
                  <Dropdown
                    // className="courseDD"
                    show={showCBSEDropdown}
                    onToggle={toggleCBSEDropdown}
                  >
                    <Dropdown.Toggle
                      as={DropdownItem}
                      className="dd-items courseDD"
                    >
                      COURSES
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropdownItem
                        className="dd-items"
                        target="_blank"
                        onClick={openProspectusPDF}
                      >
                        PROSPECTUS
                      </DropdownItem>
                      <DropdownItem
                        className="dd-items"
                        target="_blank"
                        onClick={openRecognitionPDF}
                      >
                        CENTRAL AND STATE RECOGNITION
                      </DropdownItem>
                      <DropdownItem
                        className="dd-items"
                        target="_blank"
                        onClick={handleCourseContent}
                      >
                        COURSE CONTENT
                      </DropdownItem>
                      <DropdownItem
                        className="dd-items"
                        target="_blank"
                        onClick={openFeesStructurePDF}
                      >
                        FEE STRUCTURE
                      </DropdownItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Menu>
              </Dropdown>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <TeachersTraining
        showModal={showModal}
        setShowModal={setShowModal}
        handleModalClose={handleModalClose}
      />
      <CourseContent
        showModal={showCourseContentModal}
        setShowModal={setShowCourseContentModal}
        handleModalClose={handleModalClose}
      />
      <CareerContent
        showModal={showCareerContentModal}
        setShowModal={setShowCareerContentModal}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default NavbarResponsive;
