import "react-responsive-carousel/lib/styles/carousel.css";
import "./Gallery.scss";
import Carousel from "react-bootstrap/Carousel";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

const PhotoGallery = ({ pageRef, pageNumber }) => {
  const [Data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://bvbthrissur.com/bvb_admin/index.php/Gallery_list",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result?.data.gallery);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderImages = () => {
    return Data.map((item) => (
      <div className="image-container" key={item.id}onClick={() => handleImageClick(item.image)}>
        <img src={item.image} alt="/" />
      </div>
    ));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
    document.body.style.overflow = "auto";
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="media-container" ref={pageRef} id={`page-${pageNumber}`}>
      <div className="container">
        <Row>
          <Col md={12} className="media-header">
            <div className="header-top">
              <span className="title">GALLERY</span>
            </div>
            <div className="header-sep"></div>
            <div className="header-bottom">
              <span className="subheading">Clicks From Previous Programs</span>
            </div>
          </Col>
        </Row>
      </div>
      <Carousel
        className="carousel"
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        indicators={false}
      >
        <Carousel.Item className="carousel-container">
          <div className="image-main">{renderImages()}</div>
        </Carousel.Item>
      </Carousel>

      {showModal && (
        <div className="modal-container" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="/" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
